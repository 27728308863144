import Helmet from "react-helmet"
import React from "react"
import LpScripts from "@tightrope/lpscripts"
import 'bootstrap/dist/css/bootstrap.min.css'

export default function HomepageLayout({children}) {
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        .trbm_cta {
            padding: 15px 100px !important;
            border: none !important;
            font-size: 35px !important;
            color: #fff !important;
            font-weight: bold !important;
            background: #43ba19 !important;
            border-radius: 10px !important;
            cursor: pointer !important;
            transition: .2s ease !important;
          }
        `}
      </style>
      </Helmet>
      {children}
      <LpScripts></LpScripts>
    </section>
  )
}
